import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/Other/seo';

const ThankYou = () => (
	<Layout>
		<div className='center-container'>
			<div className='row middle-xs'>
				<div className='col-xs-12 text-center'>
					<SEO title='Florin Pop - Thank you' />
					<h1>Thank you!</h1>
					<p>I'll get back to you as soon as possible!</p>
					<br />
					<img
						src='https://media.giphy.com/media/WAfGpVI1pI3e/giphy.gif'
						alt='wink wink'
					/>
				</div>
			</div>
		</div>
	</Layout>
);

export default ThankYou;
